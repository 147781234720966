import { Box, useTheme, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import FAB from "../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LoginProfile from "../../hooks/useLoginUser";
import TeamProfile from "../../hooks/useTeam";
import { useLocation } from 'react-router-dom';
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import InsightActionBox from '../../components/AccordionInsights';


const mockRows = [{
  "id": 1,
  "teamName": undefined,
  "campaign_name": undefined,
  "people_target": undefined,
  "initial_date": undefined,
  "end_date": undefined,
  "objective": undefined
}];

const ListInsights = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [className, setClassName] = useState();
  const [value, setValue] = useState('n');


  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const api = process.env.REACT_APP_API_URL;

  const location = useLocation();

  const navigate = useNavigate();

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------
  
  const [insights, setInsights] = useState([]);

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };


  const fetchData = async () => {
    const url = `${api}/insights/manager/${LoginProfile.getUserId()}/viewed/${value}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    if(resp.ok){
      console.log('insights', json);
      setInsights(json);
    }
    else{
      setInsights(null);
    }
  };

  const fetchUpdateView = async (id) => {
    const url = `${api}/insights/viewed/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    if(resp.ok){
      fetchData();
    }
    console.log('updatviewed',json);
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  const handleViewed = async (id) => {
    fetchUpdateView(id);
  }

  
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Insights by Mr. Johnes")} subtitle={t("Mr. Johnes analyzed her team's data and generated the following insights")} />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        //gridAutoRows="140px"
        gap="20px"
        m="5px 20px"
      >
      </Box>
      
      <Box >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
            <TabList
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary" >
              <Tab label={t("New Insights")} value="n" />
              <Tab label={t("Viewed Insights")} value="s" />
            </TabList>
          </Box>
          <TabPanel value="n">
            <Box display="grid" gap="20px" >
              {insights ? insights.map((itens, index) => (
                    <InsightActionBox
                      id={itens.id}
                      strengths={itens.strengths}
                      improve={itens.improve}
                      closing_remarks={itens.closing_remarks}
                      user={itens.user}
                      create_date={itens.create_date}
                      clickStatus={handleViewed}
                    />
                  )) : 
                      <Box
                      display="grid"
                      justifyContent="center"
                      textAlign="center"
                      gap={1}
                    >
                      <Box>
                      <Typography
                        variant="h3"
                        //fontWeight="bold"
                        sx={{ color: colors.grey[100], cursor: "pointer"}}
                      >
                        {t("No Data")}
                      </Typography>
                      </Box>

                      <Box>
                        <img
                          alt="profile-user"
                          width="370px"
                          height="370px"
                          src={`/assets/notfound.png`}
                          //src={`/assets/logo_All_Metrics-removebg-preview-v4.png`}
                          style={{ cursor: "pointer", borderRadius: "50%" }}
                        />
                      </Box>
                    </Box>
                }
            </Box>
          </TabPanel>
          <TabPanel value="s">
          <Box display="grid" gap="20px" >
              {insights ? insights.map((itens, index) => (
                    <InsightActionBox
                      id={itens.id}
                      strengths={itens.strengths}
                      improve={itens.improve}
                      closing_remarks={itens.closing_remarks}
                      user={itens.user}
                      create_date={itens.create_date}
                      clickStatus={handleViewed}
                      isDisableBtViewed="true"
                    />
                  )) :
                      <Box
                        display="grid"
                        justifyContent="center"
                        textAlign="center"
                        gap={1}
                      >
                        <Box>
                        <Typography
                          variant="h3"
                          //fontWeight="bold"
                          sx={{ color: colors.grey[100], cursor: "pointer"}}
                        >
                          {t("No Data")}
                        </Typography>
                        </Box>

                        <Box>
                          <img
                            alt="profile-user"
                            width="370px"
                            height="370px"
                            src={`/assets/notfound.png`}
                            //src={`/assets/logo_All_Metrics-removebg-preview-v4.png`}
                            style={{ cursor: "pointer", borderRadius: "50%" }}
                          />
                        </Box>
                      </Box>
                }
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
      
      {/* GRID & CHARTS */}
    </Box>
  );
};

export default ListInsights;
