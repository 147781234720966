import { Box, RadioGroup, Radio, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import { Formik ,Field } from "formik";
import ProgressCircle from "./ProgressCircle";
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from "./TextEditor";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const InsightActionBox = ({id, user, create_date, strengths, improve, closing_remarks, clickStatus, isDisableBtViewed}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  initialValues.strengths = strengths;
  initialValues.improve = improve;
  initialValues.closing_remarks = closing_remarks;

  return (
    <Box
      backgroundColor={colors.primary[400]}
      flex="grid"
      gridColumn="span 12"
      borderRadius="10px"
    >
      <Accordion sx={{backgroundColor: "transparent"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box width="33%">
            <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              {t("Employee")}
              <Typography
                  variant="h5"
                  //fontWeight="bold"
                  sx={{ color: colors.grey[100], padding: "0", cursor: "pointer", textAlign: "left" }}
                >
                  {user}
                </Typography>
            </Typography>
          </Box>
          <Box width="33%">
          <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              {t("Date of Insight")}:
              <Typography
                  variant="h5"
                  //fontWeight="bold"
                  sx={{ color: colors.grey[100], padding: "0", cursor: "pointer", textAlign: "left" }}
                >
                  {create_date}
                </Typography>
            </Typography>
          </Box>
          <Box width="33%">
            <Box display="flex" justifyContent="end" sx={{ padding: "0 30px"}}>
                <Button
                  disabled={isDisableBtViewed}
                  id={id}
                  onClick={() => clickStatus(id)}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "11px",
                    fontWeight: "bold",
                    padding: "3px 20px",
                  }}
                >
                      <RemoveRedEyeOutlinedIcon sx={{ mr: "10px" }} />
                      {t("Viewed")}
                    </Button>
                </Box>
              </Box>
              </AccordionSummary>
                <AccordionDetails>
                  <Box display="grid" gap={1}>
                    {("Strengths")}:
                    <Formik
                      initialValues={initialValues}
                    >
                      <Field
                        name="strengths"
                        component={TextEditor}
                        sx={{ gridColumn: "span 12" }}
                        minLines={4}
                        maxLines={6}
                        readOnly={true}
                      />
                    </Formik>

                    {("Improve")}:
                    <Formik
                      initialValues={initialValues}
                    >
                      <Field
                        name="improve"
                        component={TextEditor}
                        sx={{ gridColumn: "span 12" }}
                        minLines={4}
                        maxLines={6}
                        readOnly={true}
                      />
                    </Formik>
                    
                    {("Close Remarks")}:
                    <Formik
                      initialValues={initialValues}
                    >
                      <Field
                        name="closing_remarks"
                        component={TextEditor}
                        sx={{ gridColumn: "span 12" }}
                        minLines={4}
                        maxLines={6}
                        readOnly={true}
                      />
                    </Formik>
                  </Box>
                </AccordionDetails>
            </Accordion>
    </Box>
  );
};

const initialValues = {
  anotation: "",
};

export default InsightActionBox;
